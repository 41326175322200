const faqs = [
  {
    question: "Requisitos para solicitar la visa americana",
    answer: (
      <div>
        <p>-  Formulario DS-160: Para solicitar la visa americana, es necesario llenar el formulario en línea  DS-160. En este se proporciona información sobre el motivo del viaje y detalles personales. Al terminar, obtendrás un código de confirmación.</p> <br/>
        
        <p>- Pago de tarifa: Hay una tarifa que se debe pagar para solicitar la visa. Puedes pagarlo en línea o en un banco autorizado.</p><br/>
        <p>- Crear perfil: En el sitio web de la Embajada de Estados Unidos, debes crear un perfil en el Sistema de Citas de Visa. Aquí podrás programar una cita para una entrevista con la Embajada o Consulado más cercano.</p><br/>
        <p>- Pasaporte Válido: Asegúrate de que tu pasaporte tenga una vigencia mínima de seis meses a partir de la fecha de ingreso planificada. Además debe tener al menos dos páginas en blanco. </p><br/>
        <p>- Seguro de viaje: Aunque no es un requisito obligatorio, contratar un seguro de viaje es esencial para visitar Estados Unidos. Este cubre gastos médicos, cancelaciones de viaje, pérdida de equipaje y otros imprevistos. </p><br/>
        <p>- Foto: Para solicitar la visa americana necesitas una foto reciente que mida 2 pulgadas cuadradas (aproximadamente 50 mm cuadrados), con la cabeza centrada en el marco.</p><br/>
        <p>- Entrevista: Es necesario asistir a una entrevista en la Embajada o Consulado de Estados Unidos. Un oficial te hará preguntas sobre tu viaje, el motivo y otros detalles. Esta entrevista ayuda a determinar si cumples con los requisitos para la visa.</p><br/>
        <p>- Evidencia de vínculos: Es importante exponer que tienes razones sólidas para regresar a México después de tu viaje. Puedes respaldarlo mediante pruebas como un empleo, propiedad, estudios o familia en México. Esto demuestra que no planeas quedarte en Estados Unidos permanentemente.</p><br/>
        <p>- Documentos financieros: Debes demostrar que cuentas con los recursos necesarios para tu estancia en Estados Unidos. Esto puede incluir estados de cuenta bancarios, cartas de empleo o cualquier evidencia que muestre que puedes costear tu viaje.</p><br/>
        <p>- Itinerario detallado: Es fundamental programar cada día de tu viaje, incluyendo los lugares que visitarás y las actividades que deseas realizar. Esto muestra que tienes un plan concreto para visitar Estados Unidos.</p><br/>
        <p>- Plan de regreso: Para que te otorguen la visa americana debes demostrar que tienes la intención de volver a México. Asegúrate de tener un boleto de regreso o a otro país que vayas a visitar.</p><br/>

        <p>Obtener la visa americana no es un objetivo difícil de alcanzar, tan sólo debes planificar cada paso con detalle para evitar contratiempos. No olvides contar con tu pasaporte al día, seguro de viaje y prepárate para disfrutar de una experiencia inolvidable.</p><br/>
    
       
    
      </div>
    ),
  },

 
];

export default faqs;
